import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
})

function configRoutes() {
  return [
    {
      path: '/',
      redirect: '/',
      name: 'Home',
      component: () => import('@/containers/TheContainer'),
      children: [
        {
          path: '',
          name: 'Home',
          component: () => import('@/views/Home'),
        },
        {
          path: 'change-password',
          name: 'Change Password',
          component: () => import('@/views/ChangePassword'),
        }, 
        {
          path: 'login/azuread',
          name: 'Office 365 Login',
          component: () => import('@/views/LoginAzureAD'),
        },
        {
          path: 'login',
          name: 'Login',
          component: () => import('@/views/Login'),
        },
        {
           path: 'login-impersonate',
           name: 'Login - Impersonate User',
           component: () => import('@/views/LoginImpersonateUser'),
        },
        {
          path: 'forgot-password',
          name: 'Forgot Password',
          component: () => import('@/views/ForgotPassword'),
        },
        {
          path: 'reset-password',
          name: 'Reset Password',
          component: () => import('@/views/ResetPassword'),
        },
      ]
    },
  ]
}

