<template>
  <b-input-group>
    <template v-slot:append>
      <b-input-group-text class="form-view-enable"
                          v-on:click="isViewEnabled = !isViewEnabled">
        <i v-if="!isViewEnabled"
           class="fa fa-eye-slash"></i>
        <i v-if="isViewEnabled"
           class="fa fa-eye"></i>
      </b-input-group-text>
    </template>

    <b-form-input :type="isViewEnabled && 'text' || 'password'"
                  v-model:value.sync="val"
                  v-on:input="onInput"
                  :placeholder="placeholder"
                  :id="id"
                  :name="name"
                  :autocomplete="autocompleteName()"
                  autocorrect="off"
                  autocapitalize="off"
                  :disabled="isInputDisabled"></b-form-input>
  </b-input-group>
</template>
<script>
  export default {
    data() {
      return {
        isInputDisabled: this.disabled,
        isViewEnabled: this.enabled,
        val: this.value,
      }
    },
    props: {
      value: {
        type: String,
      },
      id: {
        type: String,
      },
      name: {
        type: String,
      },
      placeholder: {
        type: String,
      },
      disabled: {
        type: Boolean,
        default: () => false,
      },
      enabled: {
        type: Boolean,
        default: () => false,
      },
      onInput: {
        type: Function,
        default: () => function() {},
      },
    },
    methods: {
      autocompleteName() {
        return `toggle-${Math.random()}`
      },
    },

    watch: {
      val(newVal) {
        this.$emit('input', newVal)
      },
    },
  }
</script>
<style scoped>
  .form-view-enable:hover {
    cursor: pointer;
    opacity: 0.8;
  }
</style>