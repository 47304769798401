
export default (Vue) => {
  Vue.toasted.register('error',
    msg => {
      if (typeof msg.message === 'string') {
        return msg.message
      }
      return JSON.stringify(msg.message)
    },
    {
      position: 'top-center',
      type: 'error',
      theme: 'bubble',
      iconPack: 'fontawesome',
      icon: {
        name: 'exclamation-circle',
        after: true
      },
      class: 'sw__toast__error',
      duration: 5000
    })
  
  Vue.toasted.register('success', msg => msg.message, {
    position: 'top-center',
    type: 'success',
    theme: 'bubble',
    iconPack: 'fontawesome',
    icon: {
      name: 'check',
      after: true
    },
    class: 'sw__toast__success',
    duration: 5000
  })
}
