import axios from 'axios'

// Module-level variables - yes this is on purpose
//   vuex-store wasn't going to work, since promises
//   cannot be stored in localStorage, and we don't want to anyway.
let isRefreshing = false
let refreshPromise = null

export default {
  install(vue, opts = {}) {
    const { store, router } = opts
    vue.prototype.$request = axios.create()

    // Attach interceptor to add JWT to request
    vue.prototype
      .$request
      .interceptors
      .request
      .use(config => {
        if (store.getters.authToken) {
          config.headers.Authorization = `Bearer ${store.getters.authToken}`
        } else {
          delete config.headers.Authorization
        }
        return config
      })

    // Attach interceptor to redirect to login page on 401
    vue.prototype
      .$request
      .interceptors
      .response
      .use(
        config => {
          return config
        },
        async err => {
          // Not a 401, process as regular error
          if (err?.response?.status !== 401) {
            vue.prototype.$log.error('Request error', err)
            return Promise.reject(err)
          }

          // We're on the login page, it's a login failure
          if (router.currentRoute.fullPath === '/login') {
            return Promise.reject(err)
          }

          // Store off the original request
          const originalRequest = err.config
          const accessToken = await refreshAccessToken()
          originalRequest.headers.Authorization = `Bearer ${accessToken}`
          
          return await axios(originalRequest)
        })

    async function refreshAccessToken() {
      if (isRefreshing) {
        return await refreshPromise
      }

      isRefreshing = true
      refreshPromise = new Promise(async (resolve) => {
        try {
          const res = await axios({
            method: 'POST',
            url: '/api/auth/refresh',
            headers: {
              Authorization: `Bearer ${store.getters.authToken}`,
            },
          })
          store.commit('authToken', res.data?.token)
          isRefreshing = false
          refreshPromise = null
  
          return resolve(res.data?.token)
        } catch (e) {
          // Unable to auto-refresh, boot to login
          try {
            store.commit('loginRedirect', router.currentRoute.fullPath)
          } catch (e) {
            vue.prototype.$log.error('Request error', e)
          }
          window.location.href = '/login'
        }
      })

      return await refreshPromise
    }
  }
}
