import { v4 as uuidv4 } from 'uuid'
import { datadogLogs } from '@datadog/browser-logs'
import config from '@shared/services/Config'

const DATADOG_CLIENT_TOKEN = 'pub621709eb9d367931bd27fa514581def6'

class Logger {
  constructor(app, params = {}) {
    this._app = app
    this._idGenerator = params.idGenerator || uuidv4
    this._params = params
    this._extra = {}
    this._buffer = []
    this._defaultLevel = params.defaultLevel || 'info'
    this._timer = setInterval(() => {
      this._flush()
    }, 500)

    // Add event listener for window unload
    window?.addEventListener?.('beforeunload', () => {
      this._flush()
    })

    datadogLogs.init({
      clientToken: DATADOG_CLIENT_TOKEN,
      site: 'us5.datadoghq.com',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      service: app,
    })
  }

  setUser(user) {
    this._user = user
    datadogLogs.setUser(user)
    return this
  }

  setSession(session) {
    this._session = session
    return this
  }

  setExtra(extra) {
    this._extra = { ...this._extra, ...extra }
    return this
  }

  log(eventName, params = {}, ex = null) {
    return this._log(this._defaultLevel, eventName, params, ex)
  }

  trace(eventName, params = {}, ex = null) {
    return this._log('trace', eventName, params, ex)
  }

  debug(eventName, params = {}, ex = null) {
    return this._log('debug', eventName, params, ex)
  }

  info(eventName, params = {}, ex = null) {
    return this._log('info', eventName, params, ex)
  }

  warn(eventName, params = {}, ex = null) {
    return this._log('warn', eventName, params, ex)
  }

  warning(eventName, params = {}, ex = null) {
    return this._log('warn', eventName, params, ex)
  }

  error(eventName, params = {}, ex = null) {
    return this._log('error', eventName, params, ex)
  }

  _log(level, eventName, params = {}, ex = null) {
    const entry = {
      id: this._idGenerator(),
      app: this._app,
      level,
      eventName,
      timestamp: new Date(),
      user: this._user,
      session: this._session,
      params,
      extra: this._extra,
    }
    this._buffer.push(entry)
    try {
      datadogLogs.logger[level](eventName, entry, level === 'error' ? params : ex);
    } catch (e) {
      console.error(e)
    }
  }

  async _flush() {
    if (!this._buffer.length) {
      return
    }
    const copy = this._buffer.slice()
    this._buffer = []
    copy.map(entry => {
      console[entry.level]?.(entry.eventName, entry)
    })
  }
}

export default Logger