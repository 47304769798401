import 'core-js/stable'
import Vue from 'vue'
import Vuex from 'vuex'
import 'regenerator-runtime/runtime'
import App from './App'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import Loading from 'vue-loading-overlay'
import Toasted from 'vue-toasted'
import storeConfig from '@shared/store'
import CoreuiVue from '@coreui/vue'
import config from '@shared/services/Config'
import Logger from '@shared/services/Logger'
import PasswordToggleInput from '@shared/components/PasswordToggleInput'
import { 
  freeSet,
  brandSet,
} from '@coreui/icons'

// Vuex store
Vue.use(Vuex)
const store = new Vuex.Store(storeConfig)

// CSS imports
import 'vue-loading-overlay/dist/vue-loading.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Requests
import Request from '@shared/services/Request'
Vue.use(Request, { store, router })

// Sentry
import Sentry from '@shared/services/Sentry'
Sentry.init({
  Vue,
  dsn: config.SENTRY.DSN,
  tunnel: '/api/sentry/tunnel',
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 1.0,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay({ maskAllText: false, blockAllMedia: true }),
  ],
  ignoreErrors: [
    
  ],
})

// Add Vue Analytics (GA)
import VueAnalytics from 'vue-analytics'
import googleAnalytics from '@shared/services/GoogleAnalytics'
Vue.use(VueAnalytics, {
  id: googleAnalytics.id,
  router,
  autoTracking: {
    exception: true
  },
  debug: {
    sendHitTask: process.env.NODE_ENV === 'production'
  }
})

// Mixins
import UserProfile from '@shared/mixins/UserProfile'
Vue.mixin(UserProfile)

// Toasts
Vue.use(Toasted)
import globalToasts from '@shared/services/Toast'
globalToasts(Vue)

Vue.config.performance = true
Vue.use(BootstrapVue)
Vue.use(CoreuiVue)
Vue.use(Loading)
Vue.component('PasswordToggleInput', PasswordToggleInput)
Vue.prototype.$log = new Logger('AdminClient:root')

new Vue({
  el: '#app',
  router,
  store,
  //toasted,
  icons: { ...freeSet, ...brandSet },
  template: '<App/>',
  components: {
    App,
  },
})
